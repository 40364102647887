<template>  
    <div>
        <h4 class="mb-3">
            Templates
            <div class="btn-group float-end" role="group" aria-label="Basic example">
                <button class="btn" @click="showImportTemplate = true"><i class="bi bi-folder-symlink-fill me-1"></i> Importer</button>
                <button class="btn" v-if="selectedTemplates.length === 0" @click="selectedTemplates = templates.filter( t => !t.isProtected ).map( q => q._id)"><i class="bi bi-check-square me-1"></i> Tous/Aucun</button>
                <button class="btn" v-if="selectedTemplates.length > 0" @click="selectedTemplates = []"><i class="bi bi-square me-1"></i> Tous/Aucun</button>
                <button class="btn btn-sm text-danger" :disabled="selectedTemplates.length === 0" @click="showConfirmTemplateTrash = true"><i class="bi bi-trash me-1"></i> Supprimer</button>
            </div>
        </h4>

        <p style="text-align: justify;">
            Vous pouvez créer des modèles de machines (templates) à partir de machines vous appartenants.
        </p>
        <p style="text-align: justify;">
            Rendez vous dans votre espace personnel et cliquez sur le bouton correspondant pour dupliquer une machine à "l'arrêt" en modèle.
            Vous pouvez aussi le faire via le bouton "importer" ci-dessus.
            Les modèles créés seront visibles ici et vous pourrez les supprimer/editer à votre convenance. 
        </p>

        <div class="alert alert-light">
            <h6>Notes à propos des modèles</h6>
            <ul>
                <li>Un modèle n'est plus lié à la machine qui à servit de modèle. Cette dernière peux donc être supprimée.</li>
                <li>Les modèles occupent autant d'espace de stockage que des machines allouées. Elles engendrent donc des frais, modérez leur nombre.</li>
            </ul>
        </div>
        
        <div class="mb-3">
            <label for="" class="form-label">Filtrer</label>
            <input type="text" name="" id="" class="form-control mb-3" placeholder="Filtrer..." v-model="searchTemplate" @keyup.enter="search()">
        </div>

        <!-- List of Templates -->
        <ul class="list-group" v-if="loaded && templates.length > 0">
            <li class="list-group-item list-group-item-action" v-for="template in templates" :key="template._id">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <input role="button" class="form-check-input me-2" type="checkbox" v-if="!template.isProtected && template.isAvailable" :value="template._id" v-model="selectedTemplates" aria-label="...">
                                <input class="form-check-input me-2" type="checkbox" disabled v-else>
                            </td>
                            <td>
                                <i class="bi bi-box-seam me-2" v-if="template.isAvailable"></i>
                                <span class="spinner spinner-grow spinner-grow-sm me-2" v-else></span>
                            </td>
                            <th class="w-100">
                                {{template.name}}
                                <small v-if="template.isProtected" class="text-muted">
                                    <em>(protected)</em>
                                </small>
                                <small v-if="!template.isAvailable" class="text-muted">
                                    <em>En cours de création...</em>
                                </small>
                            </th>
                            <td class="text-end" role="button">
                                <i class="bi bi-arrows-angle-expand me-2"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="alert alert-light mt-3 mx-3" style="border: 1px solid lightgray;">
                    <p style="text-align: justify;" class="mb-0 text-truncate">{{template.description}} </p>
                </div>

                <em><ul>
                    <li>Créé par : {{(template.createdBy) ? template.createdBy.email : "Ancien utilisateur"}} le {{ (new Date(template.createdAt)).toLocaleDateString() }}</li>
                    <li>Famille d'OS : {{template.osType}}</li>
                    <li>Type d'instance : {{template.instanceType}}</li>
                    <li>Type disque : {{template.rootDiskType}}</li>
                    <li>Taille disque : {{template.rootDiskSize}} Go</li>
                </ul></em>

            </li>
        </ul>
        <div v-if="loaded && templates.length === 0">
            <div class="alert alert-warning">
                <p class="mb-0"><i class="bi bi-info-circle-fill me-2"></i>Vous ne disposez pas encore de templates, commencez par en créer un en cliquant sur le bouton en haut à droite.</p>
            </div>
        </div>
        <div v-if="!loaded">
            <span class="spinner spinner-border spinner-border-sm me-2"></span> {{(loadMessage) ? loadMessage : "Chargement..."}}
        </div>

        <ImportTemplate modal-id="ImportTemplateModal"
            :show="showImportTemplate"
            @close="showImportTemplate = false" />
        
        <ConfirmBox modal-id="ConfirmTemplateTrash"
            :show="showConfirmTemplateTrash"
            title="Supression des templates"
            type-of="question"
            message="Souhaitez vous vraiment supprimer ces modèles ? Les machines créés par ces modèles ne seront pas impactées ni supprimées."
            yes="Oui"
            no="non"
            @close="showConfirmTemplateTrash = false"
            @decline="showConfirmTemplateTrash = false"
            @confirm="startDeletion()" />

        <ProgressBar modal-id="ProgressBar"
            :show="showProgress"
            :percent="progressPercent"
            :message="progressMessage"
            @close="progressFinished" />

        <ErrorBox modal-id="ErrorBox"
            :show="error"
            :message="error"
            @close="error = false" />

    </div>
</template>

<script setup>

    import {onMounted, ref, toRefs, defineEmits, defineProps} from "vue"
    import axios from "axios";
    import ImportTemplate from "./components/ImportTemplate.vue";
    import ConfirmBox from "./components/generics/ConfirmBox.vue";
    import ProgressBar from "./components/generics/ProgressBar.vue";
    import ErrorBox from "./components/generics/ErrorBox.vue";

    let props = defineProps(["userInfo"]);

    // Manage loading state page
    let loaded = ref(false);
    let loadMessage = ref("");

    // Manager modals showing
    let showImportTemplate = ref(false);
    let showConfirmTemplateTrash  = ref(false);
    
    // Manage error box
    let error = ref(false);

    // Manage progress bar
    let showProgress = ref(false);
    let progressMessage = ref("");
    let progressPercent = ref(0);

    // Local vars
    let templates = ref([]);
    let searchTemplate = ref("");
    let selectedTemplates = ref([]);

    onMounted( () => {
        load();
    });

    // Load the necessary information from API
    function load(){
        loaded.value = false;
        axios.get(`/api/templates?limit=100`)
            .then( answer => {
                templates.value = answer.data;
                loaded.value  =true;
            })
            .catch( answer => {
                alert( (answer.response.data.message) ? answer.response.data.message : answer);
            })
    }

    function search(){

    }

    async function startDeletion(){
        showConfirmTemplateTrash.value = false;
        showProgress.value = true;
        try{
            let i = 0; //-> to Send axios request
            let f = 0; //-> to follow received answers
            while(i < selectedTemplates.value.length){
                let templateId = selectedTemplates.value[i];
                axios.delete(`/api/templates/${templateId}`)
                    .then( answer => {
                        progressMessage.value = `Supression en cours (${f+1}/${selectedTemplates.value.length})`;
                        progressPercent.value = ( (f+1) / selectedTemplates.value.length ) * 100;
                        if(f===selectedTemplates.value.length) progressMessage.value =`Action terminée !`;
                        f++;
                    })
                    .catch( e => {
                        progressMessage.value = `Erreur !`;
                        progressPercent.value = 100;
                        error.value = (e.response.data.message) ? e.response.data.message : e;
                        f=selectedTemplates.value.length;
                        i=selectedTemplates.value.length;
                    })
                i++;
            }
        }
        catch(e){
            progressPercent.value = 100;
            error.value = (e.response.data.message) ? e.response.data.message : e;
        }
    }

    async function progressFinished(){
        showProgress.value = false;
        progressMessage.value = "";
        progressPercent.value = 0;
        load();
    }

</script>