<template>
    <div>
        <div class="modal fade" tabindex="-1" :id="modalId" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" v-if="visible">

                    <div class="modal-body" v-if="instancesSource.length > 0 && loaded">
                        
                        <p class="text-center">
                            <i class="bi bi-box-seam fs-1"></i>
                            <br>
                            <span class="lead">
                                Nouveau modèle
                            </span>
                        </p>
                        
                        <div class="mb-3">
                            <label for="" class="form-label">Nom du modèle</label>
                            <input type="text" class="form-control" :disabled="disabled" v-model="name"/>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="mb-3">
                                    <label for="" class="form-label">Instance source</label>
                                    <select name="" id="" class="form-select" v-model="instanceSrcSelected" :disabled="disabled">
                                        <option :value="instance" v-for="instance in instancesSource" :key="instance.name">{{instance.instances[0].attributes.id}} - {{instance.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="mb-3">
                                    <label for="" class="form-label">Type d'instance</label>
                                    <select name="" id="" class="form-select" v-model="instanceType" :disabled="disabled">
                                        <option value="t2.small">t2.small</option>
                                        <option value="t3a.nano">t3a.nano</option>
                                        <option value="t3a.small">t3a.small</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="" class="form-label">Description</label>
                            <textarea rows="2" cols="30" class="form-control" :disabled="disabled" v-model="description"></textarea>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="mb-3">
                                    <label for="" class="form-label">Visible en boutique ? </label>
                                    <br>
                                    <input type="radio" class="form-check-input me-2 ms-2" name="visible" v-model="isVisible" value="true" :disabled="disabled" />Oui
                                    <br>
                                    <input type="radio" class="form-check-input me-2 ms-2" name="visible" v-model="isVisible" value="false" :disabled="disabled" />non
                                </div>
                            </div>
                            <div class="col">
                                <div class="mb-3">
                                    <label for="" class="form-label">Protéger contre la supression ? </label>
                                    <br>
                                    <input type="radio" class="form-check-input me-2 ms-2" name="protected" v-model="isProtected" value="true" :disabled="disabled" />Oui
                                    <br>
                                    <input type="radio" class="form-check-input me-2 ms-2" name="protected" v-model="isProtected" value="false" :disabled="disabled" />non
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="" class="form-label">Famille de l'OS </label>
                            <br>
                            <input type="radio" class="form-check-input me-2 ms-2" name="os" v-model="osType" value="windows" :disabled="disabled" />Windows
                            <br>
                            <input type="radio" class="form-check-input me-2 ms-2" name="os" v-model="osType" value="linux" :disabled="disabled" />Linux
                            <br>
                            <input type="radio" class="form-check-input me-2 ms-2" name="os" v-model="osType" value="other" :disabled="disabled" />Autre
                        </div>

                        <div class="alert alert-light text-muted" style="border: 1px solid lightgrey;">
                            <h6><i class="bi bi-info-circle-fill me-2"></i>Note</h6>
                            <p style="text-align: justify;" class="mb-0">Lors de l'importation, l'opération peux prendre un peu de temps. Le modèle passera en "disponible" une fois que le processus sera terminé.</p>
                        </div>

                    </div>

                    <!-- If no instance to be cloned -->
                    <div class="modal-body" v-if="instancesSource.length === 0 && loaded">
                        <h6><i class="bi bi-exclamation-triangle-fill text-warning me-2"></i>Pas d'instance(s) source</h6>
                        <p class="mb-0">
                            Il semblerait que vous ne disposiez d'aucune instance.
                            Veuillez créer au moins une instance, faire vos modifications dessus puis arrêter là afin de pouvoir la selectionner ici.
                        </p>
                    </div>

                    <!-- If loading -->
                    <div class="modal-body" v-if="!loaded">
                        <div class="modal-body text-center">
                            <span class="spinner spinner-border spinner-border-sm me-2"></span>Chargement de vos instances
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm" @click.prevent="close()" :disabled="disabled">
                            <i class="bi bi-x me-2"></i>Annuler
                        </button>
                        <button type="button" class="btn btn-sm" :disabled="disabled" v-if="instancesSource.length > 0" @click="startImportation()">
                            <i class="bi bi-check me-2"></i> Importer
                        </button>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

    import { defineProps, defineEmits, onMounted, computed, ref, toRefs } from 'vue';
    import Modal from "bootstrap/js/dist/modal";
    import axios from "axios";

    let props = defineProps(["modalId","show","template"]);
    let emits = defineEmits(["close","importationInProgress"]);
    let disabled = ref(false);

    let visible = computed( () => {
        if(props.show){
            let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
            myModal.show();
            load();
        }
        else{
            try{
                let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
                myModal.hide();
            }
            catch(e){
                return props.show;
            }
        }
        return props.show;
    });

    // Manage load state of this modal
    let loaded = ref(false);

    // local vars
    let isVisible = ref(false);
    let name = ref("");
    let description = ref("");
    let osType = ref("windows");
    let isProtected = ref(false);
    let instanceType = ref("t3a.nano");
    let instanceSrcSelected = ref(null);
    let instancesSource = ref([]);

    onMounted( () => {
        
    })

    function close(){
        emits("close");
    }

    function load(){
        loaded.value = false;
        axios.get("/api/users/me/infras")
            .then( answer => {
                let infras = answer.data.infrasResponse.filter( i => i.states );
                instancesSource.value = infras.reduce( (previous, current) => {
                    let instances = current.states.resources.filter( r => r.type === "aws_instance").filter( i => i.instances[0].attributes.instance_state === "stopped" );
                    return previous.concat( instances );
                }, []);
                loaded.value = true;
            })
            .catch( answer => {
                alert( (answer.response.data.message) ? answer.response.data.message : answer);
            })
    }

    async function startImportation(){
        disabled.value = true;
        axios.post(`/api/templates`, {
                name: name.value,
                description: description.value,
                osType: osType.value,
                instanceId: instanceSrcSelected.value.instances[0].attributes.id,
                instanceType: instanceType.value,
                rootDiskSize: instanceSrcSelected.value.instances[0].attributes.root_block_device[0].volume_size,
                isProtected: isProtected.value,
                isVisible: isVisible.value
            })
            .then( answer => {
                emits("importationInProgress");
                disabled.value = false;
            })
            .catch( answer => {
                alert( (answer.response.data.message) ? answer.response.data.message : answer);
                disabled.value = false;
            })
    }

</script>